@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/variables/breakpoints';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/variables/shadows';
@use '$styles/variables/elevation';
@use '$styles/tools/mixins/typography';
@use '$styles/values';
@use '$styles/mediaqueries';


@include mediaqueries.mediumUp {
    .howItWorksNavigationLink {
        order: -1;
    }
}

.hiddenCheckbox {
    display: none;

    &.menuToggle:checked {
        ~.container {
            .menuOpen {
                display: none;
            }

            .menuClose {
                display: block;
            }

            .navOpen {
                left: 0;
            }
        }
    }

    &:not(.garageToggle:checked)~.container,
    &:not(.blogToggle:checked)~.container,
    &:not(.serviceMenuToggle:checked)~.container {
        @include mediaqueries.largeUp {
            .desktopNav {
                top: -100vh;
            }
        }
    }

    &.serviceMenuToggle:checked,
    &.blogToggle:checked,
    &.garageToggle:checked {
        &~.overlay {
            display: block;
        }
    }

    &.motToggle:checked~.container {
        .desktopNav {
            top: 0;
        }

        .motNav {
            background-color: colors.$white;

            @include mediaqueries.largeUp {
                .iconContainer {
                    &.motBackground {
                        background-color: colors.$brandPurple;
                    }
                }

                p {
                    text-decoration: underline;
                }

                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .motMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 56px;
            }
        }
    }

    &.servicingToggle:checked~.container {
        .desktopNav {
            top: 0;
        }

        .servicingNav {
            background-color: colors.$white;

            @include mediaqueries.largeUp {
                .iconContainer {
                    &.servicingBackground {
                        background-color: colors.$purple700;
                    }
                }

                p {
                    text-decoration: underline;
                }

                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .servicingMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 56px;
            }
        }
    }

    /* Additional rules for other toggles */
    &.repairsToggle:checked~.container {
        .desktopNav {
            top: 0;
        }

        .repairsNav {
            background-color: colors.$white;

            @include mediaqueries.largeUp {
                .iconContainer {
                    &.repairsBackground {
                        background-color: colors.$brandBrightGreen;
                    }
                }

                p {
                    text-decoration: underline;
                }

                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .repairsMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 56px;
            }
        }
    }

    &.diagnosticsToggle:checked~.container {
        .desktopNav {
            top: 0;
        }

        .diagnosticsNav {
            background-color: colors.$white;

            @include mediaqueries.largeUp {
                .iconContainer {
                    &.diagnosticsBackground {
                        background-color: colors.$brandCyan;
                    }
                }

                p {
                    text-decoration: underline;
                }

                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .diagnosticsMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 56px;
            }
        }
    }

    &.garageToggle:checked~.container {
        @include mediaqueries.largeUp {
            .desktopNav {
                top: 0;
            }

            .garageNavigationMenu {
                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .garageMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 80px;
            }
        }
    }

    &.blogToggle:checked~.container {
        @include mediaqueries.largeUp {
            .desktopNav {
                top: 0;
            }


            .blogNavigationMenu {
                .desktopIcon {
                    transform: rotate(180deg);
                }
            }
        }

        .blogMenu {
            left: 0;

            @include mediaqueries.largeUp {
                top: 80px;
            }
        }
    }

    &.serviceMenuToggle:checked~.container {
        @include mediaqueries.largeUp {
            .desktopNav {
                top: 0;
            }

            .serviceNavigationMenu {
                .desktopIcon {
                    transform: rotate(180deg);
                }
            }

            .desktopServiceMenuContainer {
                top: 80px;

                .desktopServiceMenu {
                    max-width: breakpoints.$maxWidthBreakpoint;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    padding-left: 64px;
                }
            }
        }
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: colors.$neutral800;
    opacity: 0.3;
    z-index: -2;
}

.serviceNavigationMenu {
    order: 0;
    pointer-events: none;

    @include mediaqueries.largeUp {
        pointer-events: auto;
    }
}

.desktopServiceMenuContainer {
    @include mediaqueries.largeUp {
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: 56px;
        background: colors.$neutral100;
        z-index: -1;
    }
}

.nav {
    position: fixed;
    z-index: elevation.$top + 1;
    height: 56px;
    width: 100vw;

    @include mediaqueries.smallUp {
        height: 80px;
    }
}

.container {
    height: 100%;
    width: 100%;
    background-color: colors.$white;

    @include mediaqueries.upToSmall {
        border-bottom: 1px solid colors.$purple300;
    }

    @include mediaqueries.smallUp {
        background-color: transparent;
        background: linear-gradient(90deg, #5739D8 0%, #3A1DA7 100%);
    }
}

.row {
    display: flex !important;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    max-width: breakpoints.$maxWidthBreakpoint;
    margin: 0 auto;
}

.navStart {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$M;
}

.children {
    @include mediaqueries.smallUp {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: spacings.$M;
    }
}

.mobileNav {
    display: flex;
    pointer-events: auto;
    overflow-y: scroll;
    overflow-x: hidden;

    @include mediaqueries.upToLarge {
        @include mediaqueries.upToSmall {
            top: 56px;
            height: calc(100dvh - 56px);
        }

        background-color: colors.$white;
        position: absolute;
        z-index: -1;
        top: 80px;
        left: 100%;
        height: calc(100dvh - 80px);
        width: 100%;
        padding-top: spacings.$M;
        flex-direction: column;
        gap: spacings.$XXS;

        transition: left 0.3s ease-in;

        &.serviceMenu,
        &.garageMenu,
        &.blogMenu {
            top: 0;
        }
    }

    @include mediaqueries.largeUp {
        flex-direction: row;
        overflow-y: auto;

        .howItWorks {
            order: -1;
        }

        &.sideMenu {
            position: absolute;
            background-color: colors.$white;
            width: 100%;
            height: auto;
        }

    }
}

.desktopNav {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    z-index: 0;

    @include mediaqueries.largeUp {
        position: absolute;
        left: 0;
        width: 100%;
        height: 400px;
        z-index: -2;

        transition: top 0.3s ease-in;
    }
}

.sideMenu {
    left: 100%;

    @include mediaqueries.largeUp {
        left: 0;
        top: -100vh;
    }
}

.paddingContainer {
    padding-left: spacings.$M;
    padding-right: spacings.$M;

    @include mediaqueries.largeUp {
        padding: 0;
    }
}

.linkContainer {
    display: block;
    height: 60px;

    @include mediaqueries.smallUp {
        max-width: 688px;
    }
}

.link {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: colors.$neutral600;
    cursor: pointer;
    margin-right: spacings.$M;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }

    p,
    a {
        @include typography.headlinesH6TypographyStyles;
        margin-bottom: 0;
    }

    a {
        color: colors.$neutral600;
    }


    @include mediaqueries.largeUp {
        color: colors.$white;
        gap: spacings.$XXXS;

        p,
        a {

            @include typography.labelMTypographyStyles;
        }

        a {
            color: colors.$white;
        }
    }
}

.mobileIcon {
    display: block !important;

    @include mediaqueries.largeUp {
        display: none !important;
    }
}

.desktopIcon {
    display: none !important;

    @include mediaqueries.largeUp {
        display: block !important;
        transition: transform 0.3s ease-in-out;
    }
}

.serviceContainer {
    display: grid;
    grid-template-columns: 1fr;
    padding: spacings.$XXXS spacings.$XS;
    border: 1px solid colors.$neutral200;
    box-shadow: shadows.$elevationLevel2BoxShadow;
    border-radius: radii.$M;
    overflow: hidden;

    @include mediaqueries.smallUp {
        width: 540px;
        grid-template-columns: 1fr 1fr;
        padding: 0;
    }

    @include mediaqueries.largeUp {
        display: none;
    }

    @include mediaqueries.upToSmall {
        label:last-of-type {
            border-bottom: none;
        }
    }
}

.service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid colors.$neutral200;
    height: 56px;
    cursor: pointer;

    .serviceStart {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: spacings.$XS;

        p {
            @include typography.labelMTypographyStyles;
            margin: 0;
        }
    }

    @include mediaqueries.smallUp {
        width: 270px;
        border: 1px solid colors.$neutral200;
        padding: spacings.$XXXS spacings.$XS;
    }


    @include mediaqueries.largeUp {
        border: none;
        width: fit-content;
        min-width: 202px;
        justify-content: center;
        gap: spacings.$XXXS;

    }

    &:hover,
    &:active,
    &:focus {
        @include mediaqueries.largeUp {
            &.motNav {
                .iconContainer {
                    &.motBackground {
                        background-color: colors.$brandPurple;
                    }
                }
            }

            &.servicingNav {
                .iconContainer {
                    &.servicingBackground {
                        background-color: colors.$purple700;
                    }
                }
            }

            &.repairsNav {
                .iconContainer {
                    &.repairsBackground {
                        background-color: colors.$brandBrightGreen;
                    }
                }
            }

            &.diagnosticsNav {
                .iconContainer {
                    &.diagnosticsBackground {
                        background-color: colors.$brandCyan;
                    }
                }
            }

            p {
                text-decoration: underline;
            }

        }
    }

}

.submenuCtaIcons {
    display: flex;
    flex-direction: column;

    .iconContainer:not(:first-child) {
        margin-top: -10px;
        z-index: -1;
    }

    @include mediaqueries.smallUp {
        flex-direction: row;

        .iconContainer:not(:first-child) {
            margin-top: 0;
            margin-left: -10px;
            z-index: -1;
        }
    }

    @include mediaqueries.largeUp {
        display: none;
    }
}

.iconContainer {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    .icon {
        color: colors.$white;
    }

    &.motBackground {
        background-color: colors.$brandPurple;
    }

    &.servicingBackground {
        background-color: colors.$purple700;
    }

    &.repairsBackground {
        background-color: colors.$brandBrightGreen;
    }

    &.diagnosticsBackground {
        background-color: colors.$brandCyan;
    }

    @include mediaqueries.largeUp {

        &.motBackground,
        &.servicingBackground,
        &.repairsBackground,
        &.diagnosticsBackground {
            background-color: colors.$neutral400;
        }
    }
}

.hideDesktop {
    @include mediaqueries.largeUp {
        display: none;
    }
}

.login {
    display: none;
    color: colors.$white;
    text-decoration: underline;
    cursor: pointer;

    @include mediaqueries.largeUp {
        display: block;
    }
}

.navEnd {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$S;

    @include mediaqueries.smallUp {
        gap: spacings.$M;
    }
}

.mobileMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$neutral800;
    height: 24px;
    width: 24px;

    @include mediaqueries.smallUp {
        color: colors.$white;
    }

    @include mediaqueries.largeUp {
        display: none;
    }

    .menuOpen {
        display: block;
    }

    .menuClose {
        display: none;
    }
}

.returnToMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$XXXS;
    margin-bottom: spacings.$S;

    p {
        @include typography.labelMTypographyStyles;
        margin: 0;
        text-decoration: underline;
    }

    @include mediaqueries.largeUp {
        display: none;
    }
}

.scrollable {
    flex: 1;
    padding-top: spacings.$XXS;
    overflow-y: auto;
    overscroll-behavior: contain;

    @include mediaqueries.largeUp {
        padding-top: 0;
        display: flex;
        flex-direction: row;
        max-width: breakpoints.$maxWidthBreakpoint;
        width: 100%;
        margin: 0 auto;
        max-height: calc(100dvh - 80px);

    }
}

.noCtaContainer {
    @include mediaqueries.largeUp {
        padding-left: spacings.$M;
    }
}

.submenuCtaContainer {
    display: flex;
    flex-direction: column;
    gap: spacings.$M;
    padding-bottom: spacings.$M;

    @include mediaqueries.smallUp {
        flex-direction: row;
    }

    @include mediaqueries.largeUp {
        flex-direction: column;
        width: 264px;
        margin-left: 64px;
        border-right: 1px solid colors.$neutral200;
        padding-top: spacings.$M;
        gap: spacings.$S;
    }
}

.submenuCta {
    height: 80px;
    max-width: 320px;
    width: 100%;
    padding: spacings.$XXS spacings.$S;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$XS;
    border: 1px solid colors.$neutral200;
    box-shadow: shadows.$elevationLevel2BoxShadow;
    border-radius: radii.$M;
    cursor: pointer;

    @include mediaqueries.smallUp {
        @include mediaqueries.upToLarge {
            flex: 1;
            max-width: 336px;
        }
    }

    @include mediaqueries.largeUp {
        min-height: 56px;
        min-width: auto;
        height: fit-content;
        max-width: 240px;
        box-shadow: shadows.$elevationLevel1BoxShadow;
        gap: spacings.$M;
    }

    &:hover,
    &:active,
    &:focus {
        .submenuBodyContainer {
            p {
                color: colors.$purple500;

                &.info {
                    color: colors.$neutral400;
                }
            }
        }

        .arrowIcon {
            color: colors.$purple500;
        }
    }
}

.submenuCtaHeading {
    display: none;
    padding: 0 0 spacings.$XXS;
    border-bottom: 1px solid colors.$neutral200;

    p {
        margin: 0;
        color: colors.$neutral800;
        @include typography.labelMTypographyStyles;
        font-weight: 600;
    }

    @include mediaqueries.largeUp {
        display: block;
    }
}

.submenuBodyContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: spacings.$XXXS;

    p {

        @include typography.labelLTypographyStyles;
        margin: 0;
        color: colors.$neutral600;
        font-weight: 600;

        &.info {
            @include typography.paragraphSTypography;
            color: colors.$neutral400;
        }


        @include mediaqueries.largeUp {
            @include typography.labelMTypographyStyles;

            &.info {
                @include typography.paragraphXSTypography;
                color: colors.$neutral400;
            }
        }
    }
}

.arrowIcon {
    color: colors.$neutral600;
}

.optionsMenuContainer {
    display: flex;
    flex-direction: column;
    gap: spacings.$S;
    margin-bottom: spacings.$M;

    @include mediaqueries.largeUp {
        margin-left: spacings.$XL;
        padding-top: spacings.$M;
    }

    .optionsMenuHeading {
        display: flex;
        flex-direction: column;
        gap: spacings.$XXS;
        padding: spacings.$XS 0;
        border-bottom: 1px solid colors.$neutral200;

        p {
            @include typography.labelMTypographyStyles;
            font-weight: 600;
            margin: 0;
            color: colors.$neutral600;

            &.subheading {
                @include typography.paragraphSTypography;
                font-weight: 400;
                color: colors.$neutral400;
            }
        }

        @include mediaqueries.largeUp {
            padding: 0 0 spacings.$XXS;

            p {
                color: colors.$neutral800;
            }
        }

    }

    .optionsMenuItems {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: spacings.$S;


        @include mediaqueries.smallUp {
            grid-template-columns: 1fr 1fr;
            gap: spacings.$S spacings.$XL;

            &.servicingLocations,
            &.motLocations {
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.servicingCars {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        &.optionsMenuColumn {
            display: flex;
            flex-direction: column;

            &.diagnosticsColumns {
                @include mediaqueries.smallUp {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: spacings.$S spacings.$XL;
                }

                @include mediaqueries.largeUp {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: spacings.$S spacings.$XL;
                }
            }

            @include mediaqueries.largeUp {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: spacings.$S spacings.$XL;
            }
        }

        a {
            @include typography.paragraphSTypography;
            color: colors.$neutral600;

            &:hover,
            &:focus,
            &:active {
                color: colors.$purple500;
                text-decoration: underline;
            }
        }

        &.optionsMenuMargin {
            a {
                margin-right: spacings.$XXXL;
            }
        }
    }
}


.optionsMenuViewAll {
    margin: spacings.$S 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$XXXS;

    a {
        @include typography.labelSTypographyStyles;
        text-decoration: underline;
        color: colors.$purple500;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }


    .viewAllIcon {
        color: colors.$purple500;
    }
}

.loginMobileLinkContainer {
    margin-top: auto;
    height: 78px;
    width: 100%;
    border-top: 1px solid colors.$neutral200;
    display: flex;
    align-items: center;
    justify-content: center;
    background: colors.$white;

    @include mediaqueries.largeUp {
        display: none;
    }
}

.loginMobileLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacings.$XXS;

    p {
        @include typography.labelMTypographyStyles;
        color: colors.$neutral800;
    }

    &:hover,
    &:active,
    &:focus {
        p {
            color: colors.$purple500;
            text-decoration: underline;
        }
    }
}

.loginIconContainer {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: colors.$brandPurple;
    color: colors.$white;
}

.blogContainer {
    display: flex;
    flex-direction: column;
    gap: spacings.$M;
    flex: 1;

    @include mediaqueries.smallUp {
        flex-direction: row;
    }

    @include mediaqueries.largeUp {
        padding: spacings.$M spacings.$L;
        gap: 60px;
    }
}

.featuredBlog {
    display: flex;
    flex-direction: column;
    gap: spacings.$S;
    max-width: 320px;
    padding-bottom: spacings.$S;
    border-bottom: 1px solid colors.$neutral200;

    @include mediaqueries.smallUp {
        max-width: 338px;
    }

    @include mediaqueries.largeUp {
        max-width: 320px;
    }

    &:hover,
    &:active,
    &:focus {
        .featuredBlogTitle {
            color: colors.$purple500;
            text-decoration: underline;
        }
    }
}

.featuredBlogImage {
    position: relative;
    width: 100%;
    height: 168px;
    overflow: hidden;
    border-radius: radii.$S;

    @include mediaqueries.smallUp {
        height: 235px;
    }

    @include mediaqueries.largeUp {
        height: 168px;
    }
}

.previewImage {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.featuredBlogContent {
    display: flex;
    flex-direction: column;
    gap: spacings.$XS;
}

.featuredBlogDescription {
    height: 40px;
    overflow: hidden;
    margin: 0;
    @include typography.paragraphSTypography;
    color: colors.$neutral400;

    /* truncate */
    display: block;
    display: -moz-box;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
}

.featuredBlogTitle {
    @include typography.headlinesH6TypographyStyles;
    font-weight: 600;
    margin: 0;
    color: colors.$neutral600;

    /* truncate */
    display: block;
    display: -moz-box;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
}

.latestBlogs {
    max-width: 320px;
    display: flex;
    flex-direction: column;
}

.latestBlogsTitle {
    display: flex;
    flex-direction: column;
    gap: spacings.$XXS;
    padding: spacings.$XS 0;
    border-bottom: 1px solid colors.$neutral200;

    p {
        @include typography.labelMTypographyStyles;
        font-weight: 600;
        margin: 0;
        color: colors.$neutral600;

        &.subheading {
            @include typography.paragraphSTypography;
            font-weight: 400;
            color: colors.$neutral400;
        }
    }

    @include mediaqueries.largeUp {
        padding: 0 0 spacings.$XXS;

        p {
            color: colors.$neutral800;
        }
    }
}

.latestBlogsContainer {
    border-bottom: 1px solid colors.$neutral200;
    display: flex;
    align-items: center;

    max-width: 320px;

    @include mediaqueries.smallUp {
        max-width: 338px;
    }

    @include mediaqueries.largeUp {
        max-width: 320px;
    }

    p {
        @include typography.labelMTypographyStyles;
        color: colors.$neutral600;
        margin: spacings.$S 0;

        &:hover,
        &:active,
        &:focus {
            color: colors.$purple500;
            text-decoration: underline;
        }

        /* truncate */
        display: block;
        display: -moz-box;
        display: -webkit-box;
        -moz-box-orient: vertical;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.popularBlogsContainer {
    a:last-of-type {
        .latestBlogsContainer {
            border-bottom: none;
        }
    }
}

.popularBlogsHeader {
    display: flex;
    flex-direction: column;
    gap: spacings.$XXS;
    padding: spacings.$XS 0;
    border-bottom: 1px solid colors.$neutral200;
    margin-bottom: spacings.$S;

    p {
        @include typography.labelMTypographyStyles;
        font-weight: 600;
        margin: 0;
        color: colors.$neutral600;

        &.subheading {
            @include typography.paragraphSTypography;
            font-weight: 400;
            color: colors.$neutral400;
        }
    }

    @include mediaqueries.largeUp {
        padding: 0 0 spacings.$XXS;

        p {
            color: colors.$neutral800;
        }
    }
}

// nav translucency effects for AB test - GROW-1084
:global(.transparent-nav) {
    .container {
        @include mediaqueries.smallUp {
            background: transparent;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 17.5%, rgba(0, 0, 0, 0.00) 100%);
                z-index: -2;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: values.$colorWhite;
                opacity: 0;
                z-index: -1;
                transition: opacity 0.3s ease-in-out;
                box-shadow: inset 0px -1px 0px values.$colorNeutral200;
            }
        }
    }

    .nav input[type=checkbox]:checked~.container {
        @include mediaqueries.smallUp {
            .topLevelLink,
            .children a,
            .login,
            .mobileMenu {
                color: values.$colorNeutral800;
            }
            &::after {
                transition: none;
                opacity: 1;
            }
        }
    }
}

:global(.nav-docked.transparent-nav) {
    .topLevelLink,
    .children a,
    .login,
    .mobileMenu {
        color: values.$colorNeutral800;
    }
    .overlay {
        height: 100vh;
        top: 60px;
        @supports (height: 100dvh) {
            height: 100dvh;
        }
    }

    .container {
        @include mediaqueries.smallUp {
            &::after {
                opacity: 1;
            }
        }
    }
}

:global(.transparent-nav:not(.nav-docked)) {
    @include mediaqueries.smallUp {
        .navStart > a {
            filter: saturate(6) brightness(1.1) hue-rotate(4.8deg);
        }
    }
}