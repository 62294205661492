@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';

.component {
  display: inline-block;
  min-width: 113px; // don't squash when not enough space
  width: 113px;
  line-height: 0;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.desktop {
  display: none;

  @include mediaqueries.smallUp {
    display: unset;
  }
}


.mobile {
  @include mediaqueries.smallUp {
    display: none;
  }
}

// HomepageHeroNavServicesUK AB Test mods - GROW-1084
:global(.transparent-nav) {
  @include mediaqueries.smallUp {
    &:global(.nav-docked),
    nav input[type=checkbox]:checked ~ :global(.mega-nav-container) {
      .desktop {
        display: none;
      }
      
      
      .mobile {
          display: unset;
          width: 113px;
          height: 35px;
        }
    }
  }
}
